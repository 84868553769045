<template>
    <!--ЗӨВӨЛГӨӨ, МЭДЭЭЛЭЛ ДЭЛГЭРЭНГҮЙ-->
    <div class="zuvulguu_medeelel_delgerengui">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="zuvulguu_medeelel_delgerengui_title">
                      <span class="yelow">{{ 'advice' | translate }}</span>
                      {{ 'information' | translate }} <span class="detail">{{ 'detail' | translate }}</span>
                    </div>
                </div>
            </div>
          <div v-if="loading" class="loading-wrap">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
            <div class="row ROW_1">
                <div class="col-md-8">
                    <div class="zuvulguu_medeelel_delgerengui_slide_L">
                        <h5 v-if="language.short == 'mn'"> {{news.zovolgoo_mn}} </h5>
                        <h5 v-else>{{news.zovolgoo_en}}</h5>
                        <div class="time"><span>{{news.created_at | date}}</span></div>
                        <div class="right_banner">
                            <div class="b_photo">
                              <a href="">
                                <img alt="" class="photo" :src="IMAGE_URL + news.zurag">
                              </a>
                            </div>
                        </div>
                        <p class="p_text" v-if="language.short == 'mn'" v-html="news.zovolgoo_medee_mn"></p>
                        <p class="p_text" v-else v-html="news.zovolgoo_medee_en"></p>
                        <div class="socials">
                            <ShareNetwork
                                    v-if="language.short == 'mn'"
                                    network="facebook"
                                    :url="`https://job-mnca.mn/${news.id}`"
                                    :title="news.zovolgoo_mn"
                                    :description="news.zovolgoo_medee_mn"
                                    :quote="news.zovolgoo_mn"
                                    hashtags="MNCA"
                            >
                                <i class="ivu-icon ivu-icon-logo-facebook"></i>
                            </ShareNetwork>
                            <ShareNetwork
                                    v-else
                                    network="facebook"
                                    :url="`https://job-mnca.mn/${news.id}`"
                                    :title="news.zovolgoo_en"
                                    :description="news.zovolgoo_medee_en"
                                    :quote="news.zovolgoo_en"
                                    hashtags="MNCA"
                            >
                                <i class="ivu-icon ivu-icon-logo-facebook"></i>
                            </ShareNetwork>
                            <ShareNetwork
                                    v-if="language.short == 'mn'"
                                    network="twitter"
                                    :url="`https://job-mnca.mn/${news.id}`"
                                    :title="news.zovolgoo_mn"
                                    :description="news.zovolgoo_medee_mn"
                                    :quote="news.zovolgoo_mn"
                                    hashtags="MNCA"
                            >
                                <i class="ivu-icon ivu-icon-logo-twitter"></i>
                            </ShareNetwork>
                            <ShareNetwork
                                    v-else
                                    network="twitter"
                                    :url="`https://job-mnca.mn/${news.id}`"
                                    :title="news.zovolgoo_en"
                                    :description="news.zovolgoo_medee_en"
                                    :quote="news.zovolgoo_en"
                                    hashtags="MNCA"
                            >
                                <i class="ivu-icon ivu-icon-logo-twitter"></i>
                            </ShareNetwork>
                            <ShareNetwork
                                    v-if="language.short == 'mn'"
                                    network="linkedIn"
                                    :url="`https://job-mnca.mn/${news.id}`"
                                    :title="news.zovolgoo_mn"
                                    :description="news.zovolgoo_medee_mn"
                                    :quote="news.zovolgoo_mn"
                                    hashtags="MNCA"
                            >
                                <i class="ivu-icon ivu-icon-logo-linkedin"></i>
                            </ShareNetwork>
                            <ShareNetwork
                                    v-else
                                    network="linkedIn"
                                    :url="`https://job-mnca.mn/${news.id}`"
                                    :title="news.zovolgoo_en"
                                    :description="news.zovolgoo_medee_en"
                                    :quote="news.zovolgoo_en"
                                    hashtags="MNCA"
                            >
                                <i class="ivu-icon ivu-icon-logo-linkedin"></i>
                            </ShareNetwork>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="zuvulguu_medeelel_delgerengui_slide_R">
                        <ul class="AI_list">
                            <li class="li_list"  :key="index" v-for="(all_new, index) in all_news">
                                <div class="swiper">
                                  <div class="slide">
                                    <router-link :to="`/advice_information_details/${all_new.id}`">
                                      <div class="view view-first">
                                        <img :src="IMAGE_URL + all_new.zurag" alt="">
                                      </div>
                                      <div class="repost-text">
                                        <router-link :to="`/advice_information_details/${all_new.id}`">
                                          <h4 v-if="language.short == 'mn'"> {{all_new.zovolgoo_mn}} </h4>
                                          <h4 v-else> {{all_new.zovolgoo_en}} </h4>
                                        </router-link>
                                      </div>
                                      <ul class="post-tags clearfix">
                                        <li>{{all_new.created_at | date}}</li>
                                        <li class="rigth">
                                          <router-link :to="`/advice_information_details/${all_new.id}`">
                                            {{ 'detail' | translate }}<i class="ivu-icon ivu-icon-md-return-right"></i>
                                          </router-link>
                                        </li>
                                      </ul>
                                    </router-link>
                                  </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {mapGetters} from "vuex";
import {GET_ZUWULGUU_MEDEE_BY_ID_DETAILS, IMAGE_URL, GET_ZUWULGUU_MEDEE_ALL,} from "../graphql/queries";

export default {
    name: "advice_information_details",
    props: ['id'],
    data() {
        return {
            news: {},
            all_news: [],
            loading: true,
            IMAGE_URL: IMAGE_URL,
        }
    },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  mounted() {
    this.getNewsList();
    this.getZ_NewsDetailsList();
  },
  methods: {
    getNewsList() {
      this.loading = true;
      this.$apollo.query({
        query: GET_ZUWULGUU_MEDEE_BY_ID_DETAILS,
        variables: {news_id: this.id.toString()}
      }).then(({data}) => {
        this.news = data.ds_zov_medeelel_view[0];
        setTimeout(() => {
          this.loading = false;
        }, 1000)
      })
    },
    getZ_NewsDetailsList() {
      this.$apollo.query({
        query: GET_ZUWULGUU_MEDEE_ALL,
      }).then(({data}) => {
        this.all_news = data.ds_zov_medeelel_view;
      })
    }

  },
}
</script>

<style scoped>

</style>
